import React, { useEffect, useState } from "react";
import EventImage from "../../Assets/Event/Frame.png";
import SiaApi from "../../utils/SiaApi";
import { useNavigate } from "react-router";
import moment from "moment/moment";

function Event() {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const bookingId = query.get("bookingId");
  const type = query.get("type");
  const [bookingDetails, setBookingDetails] = useState({});
  const [paid,setPaid]=useState();


  const paymentCheck = async () => {
    try {
      const res = await SiaApi.post(`/payments/get_bookings`
      ,{
        id:bookingId,
        type:type==="facility_type"?"facility":"event"
      },
      );
      setPaid(res.data.result.status);
    } catch (err) {
      console.log(err);
    }
  }


  const fetchBookingDetails = async () => {
    try {
      const res = await SiaApi.post(`/bookings/search?id=${bookingId}`);
      // setBookingDetails(res.data.result[0]);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }

  const fetchData = async()=>{
    try{
      const res = await SiaApi.post(`/bookings?id=${bookingId}&type=${type}`);
      console.log(res.data.result.data[0]);
      setBookingDetails(res.data.result.data[0]);


    }catch(err){
      console.log(err);
    }
  }

  const checking = async () => {
    await paymentCheck();
    await fetchBookingDetails();
  }
  
  useEffect(() => {
    if (!bookingId || !type) {
      navigate("/");
    }
    else{
      checking();
    }
  },[]);

  return (
    <>
      <div className="my-16 mx-5  border-[1px] border-[#EFEEEA] rounded-2xl">
        <div>
          <img style={{width:"100%",height:"20vh",objectFit:"fill",borderTopLeftRadius:"20px",borderTopRightRadius:"20px"}} src={bookingDetails && bookingDetails.images_data &&  bookingDetails.images_data.length>0 && bookingDetails.images_data[0] && bookingDetails.images_data[0].image_path} />
        </div>
        <div className="py-[26px] px-[10px] flex flex-col gap-[6px]" >
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Booking Id</div>
            <div>{bookingDetails.id}</div>
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Date of Booking</div>
            <div>{moment(bookingDetails.created_at).format("DD MMM YYYY")}</div>
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Event Date</div>
            <div>{moment(bookingDetails.new_start_date).format("DD MMM YYYY")}</div>
            {/* - {moment(bookingDetails.end_date).format("DD MMM YYYY")} */}
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">No of PAX</div>
            <div>{bookingDetails.no_of_pax}</div>
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Amount Paid</div>
            <div>S${bookingDetails?.amount?.toFixed(2)}</div>
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Payment Status</div>
            <div className={`rounded-md  text-white px-4 ${bookingDetails?.payment_status==="paid"?"bg-[#028C5C]":"bg-red-400"} `} style={{textTransform:"uppercase"}}>{bookingDetails?.is_refunded===1? "Refunded":bookingDetails?.payment_status }</div>
          </div>
          <div className="flex justify-between align-middle p-2">
            <div className="text-[#98A2B3]">Status</div>
            <div className={`rounded-md  text-white px-4 ${bookingDetails?.status==="booked"?"bg-[#028C5C]":"bg-red-400"} `} style={{textTransform:"uppercase"}}>{bookingDetails?.status}</div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Event;
